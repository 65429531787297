import {STATUS_ENUM} from "../../Utils/pageStatus";
import {useEffect, useState} from "react";
import PlayerReport from "../../Components/PlayerReport/PlayerReport.component";
import {playerChronicLoad} from "../../Utils/LoadCalculator";
import {mean} from "../../Utils/utils";
import PriorityQueue from "../../Utils/PriorityQueue";

const PlayersReportPage = ({players, matchGPSData}) => {

    const [pageStatus, setPageStatus] = useState(STATUS_ENUM.LOADING);
    const [playersData, setPlayersData] = useState([]);
    const [maximals, setMaximals] = useState({});

    const selectedHeaders = [
        {name: "Distancia Total", label: "Distancia Total", "units": "m"},
        {name: "Distancia Alta Intensidad(>21)", label: "Distancia Alta Intensidad(>21)", "units": "m"},
        {name: "Distancia Sprint(>24)", label: "Distancia Sprint(>24)", "units": "m"},
        {name: "Distancia MP5", label: "Distancia MP5", "units": "m"},
        {name: "# Sprints", label: "# Sprints", "units": ""},
        {name: "Smax", label: "Velocidad max", "units": "m/s"},
        {name: "Distancia Relativa(m/min)", label: "Distancia Relativa(m/min)", "units": "m/min"},
        {name: "T", label: "Tiempo"},
        {name: "High Metabolic Load Distance", label: "Distancia Alta Carga Metabolica", "units": ""},
        {name: "Eccentric Decelerations/Accelerations", label: "Coeficiente Desaceleración/Aceleración", "units": ""},
    ]


    useEffect(() => {
        if (players.length > 0 && matchGPSData.length > 0) {
            let activePlayers = players.filter((player) => player.active);

            let lastMatch = matchGPSData[0];
            let maximals = {}

            let data = matchGPSData.map((matchData) => {
                let datum = {
                    match: matchData.match,
                    isLast: matchData.match === lastMatch.match
                }
                maximals[matchData.match._id] = {}

                let positionsPQ = {}
                selectedHeaders.forEach((header) => {
                    positionsPQ[header.label] =  new PriorityQueue();
                })
                matchData.data.filter(datum => datum.player.active).map((playerData) => {
                    let player = playerData.player
                    let playerDatum = {}
                    for (let header of selectedHeaders) {
                        playerDatum[header.label] = {
                            value: playerChronicLoad(player, [matchData], mean, header.name),
                            player: player
                        }
                        positionsPQ[header.label].enqueue(playerDatum[header.label])
                    }
                })
                datum.values = {}

                for (let header of selectedHeaders) {
                    let headerPQ = positionsPQ[header.label]
                    datum.values[header.label] = []
                    let ranking = 1
                    while (!headerPQ.isEmpty()) {
                        let tmp = headerPQ.dequeue()
                        if (ranking === 1){
                            maximals[matchData.match._id][header.label] = tmp.value
                        }
                        tmp.ranking = ranking
                        tmp.name = header.name
                        tmp.score = 10 * (tmp.value / maximals[matchData.match._id][header.label])
                        datum.values[header.label].push(tmp)
                        ranking++;
                    }
                }
                return datum;
            })
            setPlayersData(data)
            setMaximals(maximals)
            setPageStatus(STATUS_ENUM.SUCCESS)
        } else {
            setPageStatus(STATUS_ENUM.EMPTY)
        }

    }, [players, matchGPSData]);


    return <div>
        <div>
            <h1>
                Reporte de Jugadores
            </h1>
        </div>
        <div>
            {pageStatus === STATUS_ENUM.LOADING && <div>
                <h1>
                    Cargando...
                </h1>
            </div>}
            {pageStatus === STATUS_ENUM.EMPTY && <div>
                <h1>
                    No hay datos
                </h1>
            </div>}
            {pageStatus === STATUS_ENUM.SUCCESS && <div>
                {players.filter(pl => pl.active).map((player) => {
                    let filteredData = playersData.map((datum) => {
                        let tmp = {...datum, empty: false};
                        tmp.values = {}
                        Object.keys(datum.values).forEach((header) => {
                            let val = datum.values[header].filter(val => val.player.id === player.id)
                            tmp.values[header] = val.length > 0 ? val[0] : null
                            if (tmp.values[header] === null){
                                tmp.empty = true
                            }
                        });
                        return tmp
                    });
                    return <PlayerReport
                        key={player.id}
                        player={player}
                        resume={filteredData}
                        maximals={maximals}
                        selectedHeaders={selectedHeaders}
                    />
                })}
            </div>}
        </div>
    </div>


}

export default PlayersReportPage;