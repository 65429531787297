import {ResponsiveRadar} from "@nivo/radar";
import {Link} from "react-router-dom";
import {getTspanGroups, round, thousandsSeparator} from "../../Utils/utils";
import {ResponsiveLine} from "@nivo/line";
import React, {useEffect} from "react";
import moment from "moment";

const PlayerReport = ({player, resume, maximals, selectedHeaders}) => {

    const user = JSON.parse(localStorage.getItem("user"));
    const [chartData, setChartData] = React.useState([])

    const getAge = (date) => {
        let today = new Date();
        let birthDate = new Date(date);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }


    useEffect(() => {
        if (player && resume) {
            let data = []
            resume.slice(0, 10).reverse().forEach((datum) => {
                let match = datum.match
                let symbol = user.company.id !== match.team.company._id ? match.team.company.photo : match.opponent.company.photo;
                let values = datum.values
                let max = maximals[match._id]

                let scores = []
                selectedHeaders.forEach((header) => {
                    if (!values[header.label]){
                        return
                    }
                    let value = values[header.label].value
                    let score = 10 * (value / max[header.label])
                    scores.push(score)
                })

                let score = scores.reduce((a, b) => a + b, 0) / scores.length

                if (!isNaN(score)) {
                    data.push({
                        x: `${moment(match.date).format("DD/MM")}`,
                        y: round(score, 1),
                        symbol,
                        date: moment(match.date).format("DD/MM/YYYYTHH:mm:ss")
                    })
                }
            })
            setChartData([{
                id: player.name,
                data
            }])
        }
    }, [player, resume]);


    return resume.length > 0 && resume[0].empty ? null :
        <div>
            <div className="row my-4">
                <div className="col-2">
                    <img className="card-img-top" src={player.photo} alt="Card image cap"/>
                </div>
                <div className="col-9">
                    <h3>{player.name}</h3>
                    <p>
                        Edad: <span>{getAge(player.birthdate)} años</span>
                    </p>
                </div>
            </div>
            <div className="row">
                {selectedHeaders.map((header) => {
                    let lastDatum = resume[0]
                    let title = header.label
                    let datum = lastDatum.values[header.label]
                    let value = header.label.toLowerCase() === "tiempo" ? `${round(datum.value / 60, 1)} min` : `${datum.value} ${header.units}`
                    let position = datum.ranking
                    let positionExtra = ""
                    if (position === 1) {
                        positionExtra = "(Mejor)"
                    } else if (position <= 5) {
                        positionExtra = "(Top 5)"
                    } else if (position <= 10) {
                        positionExtra = "(Top 10)"
                    } else if (position > 10) {
                        positionExtra = "(Fuera de top 10)"
                    }

                    let valueExtra = ""
                    if (resume.length > 1 && !resume[1].empty) {
                        let before = resume[1]
                        let beforeValue = before.values[header.label].value
                        let delta = datum.value - beforeValue
                        let deltaPercentage = 100 * delta / beforeValue
                        valueExtra = `(${round(deltaPercentage)}% desde el último partido)`
                    }
                    let max = maximals[lastDatum.match._id][header.label]
                    let score = 10 * (datum.value / max)

                    return (
                        <div className="col-4">
                            <div className="card p-2 my-1" key={header.label}>
                                <div className="card-body">
                                    <h5 className="card-title">{title}</h5>
                                    <p className="card-text">Valor: {value} {valueExtra}</p>
                                    {header.label.toLowerCase() !== "tiempo" &&
                                        <p className="card-text">Score: {round(score, 1)}/10 {positionExtra}</p>}
                                </div>
                            </div>
                        </div>

                    )
                })}
            </div>
            <div className="row w-100" style={{height: "300px"}}>
                <ResponsiveLine
                    data={chartData}
                    margin={{top: 50, right: 20, bottom: 50, left: 100}}
                    xScale={{type: 'point'}}
                    yScale={{
                        type: 'linear',
                        min: 0,
                        max: 10,
                        stacked: false,
                        reverse: false
                    }}
                    axisTop={null}
                    axisRight={null}
                    isInteractive={true}
                    enableSlices="x"
                    enableCrosshair={true}
                    pointSymbol={(props) => {
                        let imageURL = props.datum.symbol
                        return (
                            <image
                                href={imageURL}
                                x="-15"
                                y="-15"
                                width={30}
                                height={30}
                            />
                        )
                    }}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendOffset: 36,
                        padding: 0,
                        renderTick: ({
                                         opacity,
                                         textAnchor,
                                         textBaseline,
                                         textX,
                                         textY,
                                         theme,
                                         value,
                                         x,
                                         y
                                     }) => {
                            return (
                                <g
                                    transform={`translate(${x},${y})`}
                                    style={{opacity}}
                                >
                                    <text
                                        alignmentBaseline={textBaseline}
                                        style={{fontSize: "12px"}}
                                        textAnchor={textAnchor}
                                        transform={`translate(${textX},${textY})`}
                                    >
                                        {getTspanGroups(value)}
                                    </text>
                                </g>
                            )
                        }
                    }}

                    axisLeft={{
                        legend: `Score`,
                        legendOffset: -70,
                        legendPosition: 'middle',
                        format: value => `${round(value, 1)}`,

                    }}
                />
            </div>
        </div>
}

export default PlayerReport;