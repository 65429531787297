import React, {useEffect, useState} from "react";
import Select from "react-select";
import {getTspanGroups, max, median, min, mu, round, thousandsSeparator, ValueContainer} from "../../Utils/utils";
import moment from "moment";
import {ResponsiveBar} from "@nivo/bar";
import DatePicker from "react-datepicker";
import {calculateChronicLoad, calculateSharpLoad, playerChronicLoad, playerSharpLoad} from "../../Utils/LoadCalculator";

const TrainningChart = ({trainingGPSData, matchGPSData, matchData}) => {

    const user = JSON.parse(localStorage.getItem('user'));
    const team = JSON.parse(sessionStorage.getItem('team'));

    let cols =
        user.company === "65ea346653f5498fc1401f46" ?
            [
                {name: "Distancia Total", unit: "m"},
                {name: "Distancia Relativa(m/min)", unit: "m/min"},
                {name: "Distancia Vel 18-21 km/h", unit: "m"},
                {name: "Distancia Vel 21-24 km/h", unit: "m"},
                {name: "Distancia Vel >24 km/h", unit: "m"},
                {name: "# Aceleraciones", unit: ""},
                {name: "Distancia Aceleraciones", unit: "m"},
                {name: "# Desaceleraciones", unit: ""},
                {name: "Distancia Desaceleraciones", unit: "m"},
            ] : [
                {name: "Distancia Total", unit: "m"},
                {name: "Distancia Relativa(m/min)", unit: "m/min"},
                {name: "# Sprints Alta Intensidad", unit: ""},
                {name: "# Desaceleraciones Alta Intensidad", unit: ""},
                {name: "High Metabolic Load Distance", unit: "m"},
                {name: "Distancia Alta Intensidad(>21)", unit: "m"},
                {name: "Distancia Sprint(>24)", unit: "m"},
                {name: "# Sprints", unit: ""},
                {name: "Smax", unit: "m/s"},
            ]

    const aggMethods = [
        {label: "Media", value: "mean", method: mu},
        {label: "Mediana", value: "median", method: median},
        {label: "Máximo", value: "max", method: max},
        {label: "Mínimo", value: "min", method: min},
    ]

    let positions = [
        {label:"Defensa"},
        {label:"Centrocampista"},
        {label:"Delantero"},
    ]


    const [col, setCol] = useState(cols[0]);
    const [players, setPlayers] = React.useState([]);
    const [selectedPlayers, setSelectedPlayers] = useState();
    const [dateRange, setDateRange] = useState([moment().subtract(14, 'days').toDate(), moment().toDate()])
    const [aggMethod, setAggMethod] = useState(aggMethods[2]);

    const [data, setData] = useState([]);
    const [loadData, setLoadData] = useState({});

    const [chartData, setChartData] = useState([]);
    const [chartKeys, setChartKeys] = useState([]);
    const [playersData, setPlayersData] = useState([])
    const [positionFilter, setPositionFilter] = useState(positions)


    useEffect(() => {

        console.log("Training GPS Data", trainingGPSData)

        if (trainingGPSData.length === 0 || matchData.length === 0) return;

        let  datum = {
        }

        let tmp = trainingGPSData

        let matchDatatmp = matchData
        matchDatatmp = matchDatatmp.map((match) => {
            return {
                ...match,
                date: moment(match.date)
            }
        })
        matchDatatmp.sort((a, b) => {
            return a.date.toDate() > b.date.toDate() ? -1 : 1
        })

        for (let i = 0; i < matchDatatmp.length - 1; i++) {
            let match = matchDatatmp[i]
            let previousMatch = matchDatatmp[i + 1]
            let training = tmp.filter((training) => {
                return training.date.toDate() < match.date && training.date.toDate() > previousMatch.date.toDate()
            })
            let data = {}

            training.forEach((training) => {
                data[training.day_diff_to_match] = training
            })

            if (Object.keys(data).length === 0) continue;

            datum[match.date.format()] = {
                match: match,
                data,
                date: match.date
            }

            let previousDates = Object.keys(datum).map((key) =>
                moment(key)
            ).filter((date) => date.toDate() < moment().toDate())
                .sort((a, b) => a.toDate() > b.toDate() ? -1 : 1)

            if (previousDates.length > 2) {
                setDateRange(
                    [previousDates[2].toDate(), previousDates[0].toDate()]
                )
            }
        }

        setPlayers(team.players)
        setSelectedPlayers(team.players)
        setData(datum)
    }, [trainingGPSData, matchData, matchGPSData]);

    useEffect(() => {
        if (matchGPSData.length === 0) {
            console.log("No hay datos de partidos")
            return;
        }
        if (aggMethod === undefined) {
            console.log("No hay método de agregación")
            return;
        }
        if (col === undefined) {
            console.log("No hay columna seleccionada")
            return;
        }
        let loadData = {
            sharp: calculateSharpLoad(matchGPSData[0], aggMethod.method, col.name),
            chronic: calculateChronicLoad(matchGPSData.slice(1, 5), aggMethod.method, col.name)
        }

        setLoadData(loadData)
    }, [matchGPSData, aggMethod, col]);

    useEffect(() => {
        if (Object.keys(data).length === 0) return;

        let keys = Object.keys(data)
        let lastMatch = data[keys[0]]
        keys.map((key) => {
            if (lastMatch.date.toDate() < data[key].date.toDate()) {
                lastMatch = data[key]
            }
        })

        let otherMatches = keys.filter((key) => {
            return key !== lastMatch.date.format()
        }).map((key) => {
            return data[key]
        })

        let chartData = {}
        let chartKeys = []

        let matchName = user.team.id === lastMatch.match.team._id ? lastMatch.match.opponent.company.name : lastMatch.match.team.company.name
        let chartKey = `Prep. contra: ${matchName}`
        chartKeys.push(chartKey)

        let plDatum = {}

        Object.keys(lastMatch.data).map((key) => {
            let values = []
            lastMatch.data[key].data
                .filter((playerData) => {
                    return selectedPlayers.find((player) => player.id === playerData.player.id)
                })
                .map((playerData) => {
                    let value = playerData.data[col.name]
                    if (!plDatum.hasOwnProperty(playerData.player.id)) {
                        plDatum[playerData.player.id] = {
                            player: playerData.player,
                            datum: {
                                [key]: {}
                            }
                        }
                    }
                    if (!plDatum[playerData.player.id].datum.hasOwnProperty(key)) {
                        plDatum[playerData.player.id].datum[key] = {}
                    }
                    if (!plDatum[playerData.player.id].datum[key].hasOwnProperty(chartKey)) {
                        plDatum[playerData.player.id].datum[key][chartKey] = []
                    }

                    plDatum[playerData.player.id].datum[key][chartKey].push(value)

                    values.push(value)
            })

            chartData[key] = {
                [chartKey]: Math.round(aggMethod.method(values))
            }
        })

        let generalValues = {}


        chartKey = "Entrenamientos previos"
        otherMatches.map((match) => {
            Object.keys(match.data).map((key) => {
                let values = []
                if (match.data[key].date.toDate() < dateRange[0] || match.data[key].date.toDate() > dateRange[1]) return;
                match.data[key].data
                    .filter((playerData) => {
                        return selectedPlayers.find((player) => player.id === playerData.player.id)
                    })
                    .map((playerData) => {
                        let value = playerData.data[col.name]
                        if (!plDatum.hasOwnProperty(playerData.player.id)) {
                            plDatum[playerData.player.id] = {
                                player: playerData.player,
                                datum: {
                                    [key]: {}
                                }
                            }
                        }
                        if (!plDatum[playerData.player.id].datum.hasOwnProperty(key)) {
                            plDatum[playerData.player.id].datum[key] = {}
                        }
                        if (!plDatum[playerData.player.id].datum[key].hasOwnProperty(chartKey)) {
                            plDatum[playerData.player.id].datum[key][chartKey] = []
                        }

                        plDatum[playerData.player.id].datum[key][chartKey].push(value)
                        values.push(value)
                    })

                if (!generalValues.hasOwnProperty(key)) {
                    generalValues[key] = []
                }
                generalValues[key].push(Math.round(aggMethod.method(values)))
            })
        })

        if (Object.keys(generalValues).length === 0) return;

        chartKeys.push("Entrenamientos previos")
        Object.keys(generalValues).map((key) => {
            chartData[key] = {
                ...chartData[key],
                [`Entrenamientos previos`]: aggMethod.method(generalValues[key])
            }
        })

        chartData = Object.keys(chartData).map((key) => {
            return {
                group: key,
                ...chartData[key]
            }
        }).sort((a, b) => {
            if (a.group > 0) {
                if (b.group > 0) {
                    return a.group > b.group ? 1 : -1
                } else {
                    return -1
                }
            } else {
                if (b.group > 0) {
                    return 1
                } else {
                    return a.group > b.group ? -1 : 1
                }
            }
        })


        setPlayersData(plDatum)
        setChartKeys(chartKeys)
        setChartData(chartData)

    }, [data, selectedPlayers, col, aggMethod, dateRange])

    // console.log("data", chartData, chartKeys)

    return (
        <div className="w-100">
            <div className="row mt-3">
                <span className="h3">Carga Entrenamiento</span>
            </div>
            <div className="row d-flex my-2">
                <div className="col-3">
                    <Select
                        placeholder="Seleccione jugadores"
                        closeMenuOnSelect={false}
                        components={{ValueContainer}}
                        hideSelectedOptions={false}
                        isMulti
                        options={[{name: "Todos", id: "all"}, ...players]}
                        getOptionLabel={(option) => `${option.name}`}
                        getOptionValue={(option) => option.id}
                        value={selectedPlayers}
                        onChange={(option) => {
                            if (option.find((opt) => opt.id === "all")) {
                                setSelectedPlayers(players)
                            } else {
                                setSelectedPlayers(option)
                            }
                        }}
                    />
                </div>
                <div className="col-3">
                    <Select
                        placeholder="Seleccione métríca"
                        options={cols}
                        getOptionLabel={(option) => `${option.name}`}
                        getOptionValue={(option) => option.name}
                        value={col}
                        onChange={(option) => setCol(option)}
                    />
                </div>
                <div className="col-3">
                    <Select
                        placeholder="Seleccione método de agregación"
                        options={aggMethods}
                        value={aggMethod}
                        onChange={(option) => setAggMethod(option)}
                    />
                </div>
                <div className="col-3">
                    <p>Seleccione el rango de fechas para comparar</p>
                    <DatePicker
                        selectsRange={true}
                        startDate={dateRange[0]}
                        endDate={dateRange[1]}
                        onChange={(dates) => setDateRange(dates)}
                        dateFormat="dd/MM/yyyy"
                    />
                </div>

            </div>
            <div className="row" style={{height: "600px"}}>
                <ResponsiveBar
                    data={chartData}
                    keys={chartKeys}
                    indexBy="group"
                    groupMode="grouped"
                    margin={{top: 50, right: 200, bottom: 50, left: 90}}
                    padding={0.3}
                    valueFormat={value => `${round(value, 2)}`}
                    maxValue={loadData.sharp || loadData.chronic ? max([loadData.sharp * 1.1, loadData.chronic * 1.1]) : "auto"}
                    markers={[
                        {
                            axis: 'y',
                            value: loadData.sharp,
                            lineStyle: {stroke: 'green', strokeWidth: 2},
                            legend: 'Carga Aguda',
                        }, {
                            axis: 'y',
                            value: loadData.chronic,
                            lineStyle: {stroke: 'red', strokeWidth: 2},
                            legend: 'Carga Crónica',
                        }
                    ]}
                    axisBottom={{
                        renderTick: ({
                                         opacity,
                                         textAnchor,
                                         textBaseline,
                                         textX,
                                         textY,
                                         theme,
                                         value,
                                         x,
                                         y
                                     }) => {
                            return (
                                <g
                                    transform={`translate(${x},${y})`}
                                    style={{opacity}}
                                >
                                    <text
                                        alignmentBaseline={textBaseline}
                                        style={{fontSize: "12px"}}
                                        textAnchor={textAnchor}
                                        transform={`translate(${textX},${textY})`}
                                    >
                                        {getTspanGroups(value > 0 ? `+${value}` : value)}
                                    </text>
                                </g>
                            )
                        }
                    }}
                    axisLeft={{
                        format: value => `${round(value, 2)}`,
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: `Valor`,
                        legendPosition: 'middle',
                        legendOffset: -70
                    }}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 120,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 20,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                    isInteractive={true}
                />

            </div>
            <div className="container">
                {positionFilter.length < 3 &&
                    <div className="mt-1 mb-5">
                        <button className="btn btn-info" onClick={() => setPositionFilter(positions)}>Limpiar filtro de
                            posiciones
                        </button>
                    </div>
                }
                {positionFilter.map((position) => {
                    let cards = Object.values(playersData)
                        .filter((data) => data.player.position === position.label)
                        .map((data) => {
                            let player = data.player
                            let datum = data.datum

                            let sharpLoad = playerSharpLoad(player, matchGPSData[0], aggMethod.method, col.name)
                            let chronicLoad = playerChronicLoad(player, matchGPSData.slice(1, 5), aggMethod.method, col.name)


                            let chartData = Object.keys(datum).map((key) => {
                                let final = {group: key}
                                let val = datum[key]
                                let values = Object.keys(val).map((key) => {
                                    let values = val[key]
                                    final[key] = Math.round(aggMethod.method(values))
                                })
                                return final
                            })

                            chartData.sort((a, b) => {
                                if (a.group > 0) {
                                    if (b.group > 0) {
                                        return a.group > b.group ? 1 : -1
                                    } else {
                                        return -1
                                    }
                                } else {
                                    if (b.group > 0) {
                                        return 1
                                    } else {
                                        return a.group > b.group ? -1 : 1
                                    }
                                }
                            })

                            return (<div className="col-6" key={player.id}>
                                <div className="card my-3">
                                    <div className="card-header">
                                        <h5 className="text text-muted subtitle">
                                            {player.name}
                                        </h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="row w-100" style={{height: "300px"}}>
                                            <ResponsiveBar
                                                data={chartData}
                                                keys={chartKeys}
                                                indexBy="group"
                                                groupMode="grouped"
                                                margin={{top: 50, right: 10, bottom: 50, left: 90}}
                                                padding={0.3}
                                                valueFormat={value => `${round(value, 2)}`}
                                                maxValue={sharpLoad || chronicLoad ? max([sharpLoad * 1.1, chronicLoad * 1.1]) : "auto"}
                                                markers={sharpLoad || chronicLoad ? [
                                                    {
                                                        axis: 'y',
                                                        value: sharpLoad,
                                                        lineStyle: {stroke: 'green', strokeWidth: 2},
                                                        legend: 'Carga Aguda',
                                                    }, {
                                                        axis: 'y',
                                                        value: chronicLoad,
                                                        lineStyle: {stroke: 'red', strokeWidth: 2},
                                                        legend: 'Carga Crónica',
                                                    }
                                                ] : []}
                                                axisBottom={{
                                                    renderTick: ({
                                                                     opacity,
                                                                     textAnchor,
                                                                     textBaseline,
                                                                     textX,
                                                                     textY,
                                                                     theme,
                                                                     value,
                                                                     x,
                                                                     y
                                                                 }) => {
                                                        return (
                                                            <g
                                                                transform={`translate(${x},${y})`}
                                                                style={{opacity}}
                                                            >
                                                                <text
                                                                    alignmentBaseline={textBaseline}
                                                                    style={{fontSize: "12px"}}
                                                                    textAnchor={textAnchor}
                                                                    transform={`translate(${textX},${textY})`}
                                                                >
                                                                    {getTspanGroups(value > 0 ? `+${value}` : value)}
                                                                </text>
                                                            </g>
                                                        )
                                                    }
                                                }}
                                                axisLeft={{
                                                    format: value => `${round(value, 2)}`,
                                                    tickSize: 5,
                                                    tickPadding: 5,
                                                    tickRotation: 0,
                                                    legend: `Valor`,
                                                    legendPosition: 'middle',
                                                    legendOffset: -70
                                                }}

                                                isInteractive={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        })

                    return <div key={position.label} className="">
                        <div className="row">
                            <h1>{position.label}</h1>
                        </div>
                        <div className="row">
                            {cards}
                        </div>
                    </div>

                    })
                }
            </div>
        </div>
    )
}

export default TrainningChart;