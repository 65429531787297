import "../../Template/TemplateStyle.styles.css";
import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import HTTPServices from "../../Utils/HTTPServices";
import moment from "moment";
import {STATUS_ENUM} from "../../Utils/pageStatus";
import Swal from "sweetalert2";
import LoadingPlaceHolder from "../../Components/LoadingPlaceHolder/LoadingPlaceHolder";

const DailyPlayerWellnessPage = () => {
//     get argument from url
    const { playerId } = useParams();

    const [player, setPlayer] = useState({});
    const [status, setStatus] = useState(STATUS_ENUM.LOADING);
    const [type, setType] = useState("wellness");
    const [data, setData] = useState(0);

    useEffect(() => {
        try {
            let client = new HTTPServices();
            client.openPlayer(playerId).then((response) => {
                setPlayer(response.data.player);
                client.openPlayerExams(playerId).then((response) => {
                    let exams = response.data.exams;
                    if (exams.length === 0) {
                        setType("wellness")
                        setStatus(STATUS_ENUM.SUCCESS);
                    } else if (exams.length === 1) {
                        setType("subjective_load")
                        setStatus(STATUS_ENUM.SUCCESS);
                    } else {
                        setStatus(STATUS_ENUM.EMPTY);
                    }
                }).catch((error) => {
                    setStatus(STATUS_ENUM.ERROR);
                });
            }).catch((error) => {
                setStatus(STATUS_ENUM.ERROR);
            });

        } catch (error) {
            setStatus(STATUS_ENUM.ERROR);
        }
    }, [playerId]);

    const sendData = (event) => {
        event.preventDefault();
        let client = new HTTPServices();
        let payload = {
            key: type === "wellness" ? "wellness" : "load",
            value: data,
            exam_type: type
        };
        client.openSendPlayerExams(playerId, payload).then((response) => {
            Swal.fire({
                icon: 'success',
                title: 'Datos enviados',
                text: 'Los datos han sido enviados correctamente'
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    return (<div>
            <div className="navbar navbar-expand-md fixed-top navbar-dark bg-main">
                <div className="container">
                    <a className="navbar-brand d-flex align-items-center" href="/home">
                        <img className="" src="/images/slab-logo-dark.jpeg" alt="" height="80"/>
                        <span className="text-white h3 navbar-text ml-2 slab-font">SLAB</span>
                    </a>
                    <div className="collapse navbar-collapse d-flex justify-content-center">
                        <li className={`nav-item item`}>
                            <span className="nav-link slab-font text-white" >Estado de Salud</span>
                        </li>
                    </div>
                </div>
            </div>
        <div className="container navbar-margin">
            {
                status === STATUS_ENUM.LOADING ?
                    <LoadingPlaceHolder loading={true}/> : null

            }
            { status === STATUS_ENUM.ERROR ?
                <div className="row justify-content-center">
                    <div className="col-12">
                        <h1 className="text-center slab-font">Error</h1>
                        <p className="text-center">Ha ocurrido un error, por favor intenta de nuevo</p>
                    </div>
                </div> : null
            }
            {
                status === STATUS_ENUM.EMPTY ?
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <h1 className="text-center slab-font">Listo!</h1>
                            <p className="text-center">Haz contestado todos los cuestionarios de hoy!</p>
                        </div>
                    </div> : null

            }
            { status === STATUS_ENUM.SUCCESS ?
                <div className="row justify-content-center">
                    <div className="col-12">
                        <h1 className="text-center slab-font">Wellness</h1>
                        <p className="text-center">{ type === "wellness" ?
                            "Aquí podras cargar tu estado de disponibilidad para el entrenamiento del día de hoy" :
                            "Aquí podras cargar la carga de entrenamiento del día de hoy"
                        }</p>
                        <hr/>
                    </div>

                    <div className="col-12">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <h2 className="text-center">{player.name}</h2>
                            </div>
                            <div className="col-12">
                                <h3 className="text-center">{player.position}</h3>
                            </div>
                        </div>
                    </div>


                    <div className="col-12 my-3">
                        {type === "wellness" ? <form onSubmit={sendData}>
                                <div className="form-group">
                                    <label htmlFor="wellness">Estado de disponibilidad</label>
                                    <input type="number" min={1} max={5} className="form-control" id="wellness" aria-describedby="wellnessHelp"
                                           placeholder="Ingresa tu estado de disponibilidad" value={data} onChange={(e) => {
                                        setData(e.target.value)
                                    }}/>
                                    <small id="wellnessHelp" className="form-text text-muted">Ingresa un valor entre 1 y
                                        5</small>
                                </div>
                                <button type="submit" className="btn btn-primary">Enviar</button>
                            </form> :
                            <form onSubmit={sendData}>
                                <div className="form-group">
                                    <label htmlFor="load">Carga subjetiva</label>
                                    <input type="number" min={1} max={10} className="form-control" id="load" aria-describedby="loadHelp"
                                           placeholder="Ingresa tu carga subjetiva" value={data} onChange={(e) => {
                                        setData(e.target.value)
                                    }}/>
                                    <small id="loadHelp" className="form-text text-muted">Ingresa un valor entre 1 y
                                        10</small>
                                </div>
                                <button type="submit" className="btn btn-primary">Enviar</button>
                            </form>
                        }

                    </div>

                    {type === "wellness" ?
                        // create a legen for the wellness form, where 1 is the worst and 5 is the best
                        // this legend should be a horizontal line with a gradient from red to green with 5 points
                        <>
                        <div className="col-12 row justify-content-between">
                            <span>Disponibilidad baja</span>
                            <span>Disponibilidad alta</span>
                        </div>
                        <div className="col-12" style={{
                            background: "linear-gradient(to right, red, yellow, green)",
                        }}>
                            <div className="row justify-content-center">
                            <div className="col-2">
                                    <span className="text-center">1</span>
                                </div>
                                <div className="col-2">
                                    <span className="text-center">2</span>
                                </div>
                                <div className="col-2">
                                    <span className="text-center">3</span>
                                </div>
                                <div className="col-2">
                                    <span className="text-center">4</span>
                                </div>
                                <div className="col-2">
                                    <span className="text-center">5</span>
                                </div>
                            </div>
                        </div>
                        </>:
                        <>
                        <div className="col-12 row justify-content-between">
                            <span>Carga baja</span>
                            <span>Carga alta</span>
                        </div>
                        <div className="col-12" style={{
                            background: "linear-gradient(to right, green, yellow, red)",
                        }}>
                            <div className="row justify-content-center">
                                <div className="col-1">
                                    <span className="text-center">1</span>
                                </div>
                                <div className="col-1">
                                    <span className="text-center">2</span>
                                </div>
                                <div className="col-1">
                                    <span className="text-center">3</span>
                                </div>
                                <div className="col-1">
                                    <span className="text-center">4</span>
                                </div>
                                <div className="col-1">
                                    <span className="text-center">5</span>
                                </div>
                                <div className="col-1">
                                    <span className="text-center">6</span>
                                </div>
                                <div className="col-1">
                                    <span className="text-center">7</span>
                                </div>
                                <div className="col-1">
                                    <span className="text-center">8</span>
                                </div>
                                <div className="col-1">
                                    <span className="text-center">9</span>
                                </div>
                                <div className="col-1">
                                    <span className="text-center">10</span>
                                </div>
                            </div>
                        </div>
                        </>
                    }

                </div> : null
            }
        </div>
    </div>)
}

export default DailyPlayerWellnessPage;