import moment from "moment/moment";
import Select, { components, ValueContainerProps } from "react-select";
import React, {useEffect, useState} from "react";
import {ValueContainer} from "../../Utils/utils";
import {getTspanGroups, mu, round, std, thousandsSeparator} from "../../Utils/utils";
import { ResponsiveBoxPlot } from '@nivo/boxplot'
import {ResponsiveBar} from "@nivo/bar";
import {ResponsiveLine, ResponsiveLineCanvas} from "@nivo/line";
import {findAllByDisplayValue} from "@testing-library/react";
import {MdOutlineKeyboardBackspace} from "react-icons/md";


const GPSChartComponent = ({GPSdata}) => {

    const user = JSON.parse(localStorage.getItem("user"));

    let cols =
        user.company.id === "65ea346653f5498fc1401f46" ?
            [
                {name: "Distancia Total", unit: "m"},
                {name: "Distancia Relativa(m/min)", unit: "m/min"},
                {name: "Distancia Vel 12-18 km/h", unit: "m"},
                {name: "Distancia Vel 18-21 km/h", unit: "m"},
                {name: "Distancia Vel 21-24 km/h", unit: "m"},
                {name: "Distancia Vel >24 km/h", unit: "m"},
                {name: "Distancia Max Intensidad", unit: "m"},
                {name: "# Aceleraciones", unit: ""},
                {name: "Distancia Aceleraciones", unit: "m"},
                {name: "# Desaceleraciones", unit: ""},
                {name: "Distancia Desaceleraciones", unit: "m"},
            ] : [
        {name: "Distancia Total", unit: "m"},
        {name: "Distancia Relativa(m/min)", unit: "m/min"},
        {name: "# Sprints Alta Intensidad", unit: ""},
        {name: "# Desaceleraciones Alta Intensidad", unit: ""},
        {name: "High Metabolic Load Distance", unit: "m"},
        {name: "Distancia Alta Intensidad(>21)", unit: "m"},
        {name: "Distancia Sprint(>24)", unit: "m"},
        {name: "# Sprints", unit: ""},
        {name: "Smax", unit: "m/s"},
    ]

    let timeCol = user.company.id === "65ea346653f5498fc1401f46" ? "Tiempo final" : "T"

    let positions = [
        {label:"Defensa"},
        {label:"Centrocampista"},
        {label:"Delantero"},
    ]


    const team = JSON.parse(sessionStorage.getItem('team'));

    const [data, setData] = useState([]);
    const [matches, setMatches] = React.useState(null);
    const [match, setMatch] = React.useState(null);
    // const [data, setData] = useState([]);
    const [col, setCol] = useState(cols[0]);
    const [players, setPlayers] = React.useState([]);
    const [selectedPositions, setSelectedPositions] = useState(positions);
    const [selectedPlayers, setSelectedPlayers] = useState();
    const [positionFilter, setPositionFilter] = useState(positions);
    const [drilldownLevel, setDrilldownLevel] = useState(0);
    const [winowSize, setWindowSize] = useState(20);
    const [timeFilter, setTimeFilter] = useState(0);
    const [playerValues, setPlayerValues] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [matchSymbols, setMatchSymbols] = useState({});


    useEffect(() => {
        if (GPSdata.length === 0){
            console.log("no data")
            return;
        }

        let symbols = {}
        GPSdata.map((match) => {
            let date = moment(match.match.date).format("DD/MM")
            let symbol = user.company.id !== match.match.team.company._id ? match.match.team.company.photo : match.match.opponent.company.photo;
            symbols[date] = symbol
        })
        setMatchSymbols(symbols)

        let players = {}
        let datum = GPSdata.slice(0, winowSize).reverse()

        let matchesData = datum.map((match) => {
            match.data.forEach((player) => {
                if (!players[player.player.id]) {
                    players[player.player.id] = player.player
                }
            })
            return {
                id: match._id,
                team: match.match.team,
                opponent: match.match.opponent,
                date: match.date
            }
        })

        setData(datum)
        setPlayers(Object.values(players))
        setSelectedPlayers(Object.values(players))
        setMatches(matchesData);
    }, [GPSdata]);

    useEffect(() => {
        if (data.length > 0) {
            setMatch(data[0].match)
        }
    }, [data]);

    useEffect(() => {
        if (data.length === 0 || players.length === 0) return;
        let playerValues = processPlayerValues();
        let chartData = processChartData();

        setPlayerValues(playerValues)
        setChartData(chartData)

    }, [data, players, selectedPlayers, selectedPositions, col, timeFilter, positionFilter, drilldownLevel]);

    let positionsNames = selectedPositions.map((position) => position.label);

    const processPlayerValues = () => {

        let chartData = data.slice(0, 10).reverse().flatMap((match) => {
            let group = `${moment(match.date).format("DD/MM")}`;
            let symbol = user.company.id !== match.match.team.company._id ? match.match.team.company.photo : match.match.opponent.company.photo;
            let values = match.data
                .filter((player) => positionsNames.includes(player.player.position))
                .filter((player) => selectedPlayers.filter((selectedPlayer) => selectedPlayer.id === player.player.id).length > 0)
                .map((player) => {
                    return {value: player.data[col.name], position: player.player.position, name: player.player.name, date: moment(match.match.date).format("DD/MM/YYYYTHH:mm:ss")}
                })

            return values.map((value) => {
                return {
                    id: value.name,
                    position: value.position,
                    data: [{x: group, y: round(value.value, 0), date: value.date, symbol}]
                }
            })
        })

        const arrayHashmap = chartData.reduce((obj, item) => {
            obj[item.id] ? obj[item.id].data.push(...item.data) : (obj[item.id] = { ...item });
            return obj;
        }, {});

        chartData = Object.values(arrayHashmap);

        // chartData =chartData.map((player) => {
        //     player.data.sort((a, b) => {
        //         return a.date > b.date ? -1 : 1
        //     })
        //     return player
        // })

        return chartData;

    }

    const processChartData = () => {
        let chartData = data.flatMap((match) => {
            let group =  user.company.id !== match.match.team.company._id ? `${match.match.team.company.name}` : `${match.match.opponent.company.name}`;
            let symbol = user.company.id !== match.match.team.company._id ? match.match.team.company.photo : match.match.opponent.company.photo;
            let date = moment(match.match.date).format("DD/MM");
            let values = match.data
                .filter((player) => positionsNames.includes(player.player.position))
                .filter((player) => player.data[timeCol] >= timeFilter * 60)
                .filter((player) => selectedPlayers.filter((selectedPlayer) => selectedPlayer.id === player.player.id).length > 0)
                .map((player) => {
                    return {value: player.data[col.name], position: player.player.position, name: player.player.name}
                })

            if (drilldownLevel > 0) {
                let data = {
                    group: group,
                    date: date,
                    symbol
                }
                positionsNames.forEach((position) => {
                    data[position] = round(
                        mu(values.filter((value) => value.position === position).map((value) => value.value)),
                        0)
                })

                return [data]

            } else if (drilldownLevel === 0) {
                let data = {
                    group: group,
                    symbol,
                    x: date,
                    y: round(mu(values.map((value) => value.value)), 0)
                }

                return [data]
            }
        })

        // chartData.sort((a, b) => {
        //     return moment(a.date).date() > moment(b.date).date() ? -1 : 1
        // })
        return drilldownLevel == 0 ? {id: col.name, data: chartData} : positionFilter.map((position) => {
            return {
                id: position.label,
                data: chartData.filter((data) => data[position.label] > 0)
                    .map((data) => { return {key: data.group, x: data.date, y: data[position.label], symbol: data.symbol, }})
                }
        })

    }

    return (
        <div className="w-100">
            <div className="row mt-3">
                <span className="h3">Estadisticas GPS</span>
            </div>
            <div className="row d-flex my-2">
                <div className="col-3">
                    <Select
                        placeholder="Seleccione jugadores"
                        closeMenuOnSelect={false}
                        components={{ValueContainer}}
                        hideSelectedOptions={false}
                        isMulti
                        options={players}
                        getOptionLabel={(option) => `${option.name}`}
                        getOptionValue={(option) => option.id}
                        value={selectedPlayers}
                        onChange={(option) => setSelectedPlayers(option)}
                    />
                </div>
                <div className="col-3">
                    <Select
                        placeholder="Seleccione métríca"
                        options={cols}
                        getOptionLabel={(option) => `${option.name}`}
                        getOptionValue={(option) => option.name}
                        value={col}
                        onChange={(option) => setCol(option)}
                    />
                </div>
                <div className="col-3">
                    {/*    text and checkbox*/}
                    <span>
                        <input
                            type="checkbox"
                            id="timefilter"
                            checked={timeFilter > 0}
                            onChange={(event) => {
                                if (event.target.checked) {
                                    setTimeFilter(75)
                                } else {
                                    setTimeFilter(0)
                                }
                            }}
                        /> Filtrar jugadores con menos de 75 minutos
                    </span>


                </div>
                <div className="col-3">
                    <Select
                        placeholder="Seleccione posiciones"
                        options={positions}
                        closeMenuOnSelect={false}
                        isMulti
                        value={selectedPositions}
                        defaultValue={positions}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.label}
                        onChange={(option) => setSelectedPositions(option)}
                        // onChange={(option) => console.log(option)}
                    />
                </div>
            </div>
            <div className="row">
                {drilldownLevel > 0 &&
                    <a onClick={() => setDrilldownLevel(0)} href="#">
                        <MdOutlineKeyboardBackspace/> Atras
                    </a>
                }

            </div>
            <div className="row">
                <div className="col" style={{minHeight: "600px"}}>
                    {
                        drilldownLevel === 0 && chartData.data &&
                        <ResponsiveLine
                            data={[chartData]}
                            // keys={[col.name]}
                            // indexBy="group"
                            margin={{top: 50, right: 50, bottom: 50, left: 90}}
                            padding={0.3}
                            tooltipFormat={value => `${thousandsSeparator(value)} ${col.unit}`}
                            valueFormat={value => `${thousandsSeparator(value)} ${col.unit}`}
                            yScale={{
                                type: 'linear',
                                min: 0,
                                max: 'auto',
                                stacked: false,
                                reverse: false
                            }}
                            axisTop={null}
                            axisRight={null}
                            isInteractive={true}
                            enableSlices="x"
                            enableCrosshair={true}
                            pointSymbol={(props) => {
                                let imageURL = props.datum.symbol
                                return (
                                    <image
                                        href={imageURL}
                                        x="-15"
                                        y="-15"
                                        width={30}
                                        height={30}
                                    />
                                )
                            }}
                            // theme={{
                            //     axis: {
                            //         ticks: {
                            //             text: {
                            //                 fontSize: 12
                            //             }
                            //         }
                            //     }
                            // }}
                            // axisBottom={{
                            //     tickSize: 5,
                            //     tickPadding: 5,
                            //     tickRotation: 0,
                            //     legend: 'Partidos',
                            //     legendPosition: 'middle',
                            //     legendOffset: 36,
                            //     renderTick: ({
                            //                      opacity,
                            //                      textAnchor,
                            //                      textBaseline,
                            //                      textX,
                            //                      textY,
                            //                      theme,
                            //                      value,
                            //                      x,
                            //                      y
                            //                  }) => {
                            //         return (
                            //             <g
                            //                 transform={`translate(${x},${y})`}
                            //                 style={{opacity}}
                            //             >
                            //                 <text
                            //                     alignmentBaseline={textBaseline}
                            //                     style={{fontSize: "12px"}}
                            //                     textAnchor={textAnchor}
                            //                     transform={`translate(${textX},${textY})`}
                            //                 >
                            //                     {getTspanGroups(value)}
                            //                 </text>
                            //             </g>
                            //         )
                            //     }
                            // }}
                            axisLeft={{
                                format: value => `${thousandsSeparator(value)} ${col.unit}`,
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: `${col.name} (${col.unit})`,
                                legendPosition: 'middle',
                                legendOffset: -70
                            }}
                            isInteractive={true}
                            animate={true}
                            onClick={(data, event) => {
                                setDrilldownLevel(1)
                            }}
                        />
                    }
                    {drilldownLevel > 0 &&
                        <ResponsiveLine
                            data={chartData}
                            // keys={positionsNames}
                            // indexBy="group"
                            // groupMode="grouped"
                            margin={{top: 50, right: 130, bottom: 50, left: 90}}
                            padding={0.3}
                            valueFormat={value => `${thousandsSeparator(value)} ${col.unit}`}
                            yScale={{
                                type: 'linear',
                                min: 0,
                                max: 'auto',
                                stacked: false,
                                reverse: false
                            }}
                            axisTop={null}
                            axisRight={null}
                            isInteractive={true}
                            enableSlices="x"
                            enableCrosshair={true}
                            // pointSymbol={(props) => {
                            //     let imageURL = props.datum.symbol
                            //     return (
                            //         <image
                            //             href={imageURL}
                            //             x="-15"
                            //             y="-15"
                            //             width={30}
                            //             height={30}
                            //         />
                            //     )
                            // }}
                            axisBottom={{
                                renderTick: ({
                                                 opacity,
                                                 textAnchor,
                                                 textBaseline,
                                                 textX,
                                                 textY,
                                                 theme,
                                                 value,
                                                 x,
                                                 y
                                             }) => {
                                    let imageURL = matchSymbols[value]
                                    return (
                                        <g
                                            transform={`translate(${x},${10})`}
                                            style={{opacity}}
                                        >
                                            <image
                                                href={imageURL}
                                                x="-10"
                                                y="15"
                                                width={20}
                                                height={20}
                                            />

                                            <text
                                                alignmentBaseline={textBaseline}
                                                style={{fontSize: "12px"}}
                                                textAnchor={textAnchor}
                                                transform={`translate(${textX},${textY})`}
                                            >
                                                {value}
                                            </text>
                                        </g>
                                    )
                                }
                            }}
                            // axisLeft={{
                            //     format: value => `${thousandsSeparator(value)} ${col.unit}`,
                            //     tickSize: 5,
                            //     tickPadding: 5,
                            //     tickRotation: 0,
                            //     legend: `${col.name} (${col.unit})`,
                            //     legendPosition: 'middle',
                            //     legendOffset: -70
                            // }}
                            // legends={[
                            //     {
                            //         dataFrom: 'keys',
                            //         anchor: 'bottom-right',
                            //         direction: 'column',
                            //         justify: false,
                            //         translateX: 120,
                            //         translateY: 0,
                            //         itemsSpacing: 2,
                            //         itemWidth: 100,
                            //         itemHeight: 20,
                            //         itemDirection: 'left-to-right',
                            //         itemOpacity: 0.85,
                            //         symbolSize: 20,
                            //         effects: [
                            //             {
                            //                 on: 'hover',
                            //                 style: {
                            //                     itemOpacity: 1
                            //                 }
                            //             }
                            //         ]
                            //     }
                            // ]}
                            legends={[
                                {
                                    anchor: 'right',
                                    direction: 'column',
                                    itemHeight: 20,
                                    itemWidth: 160,
                                    toggleSerie: true,
                                    translateX: 200
                                }
                            ]}
                            isInteractive={true}
                            onClick={(data, event) => {
                                setDrilldownLevel(2)
                                setPositionFilter([{label: data.id}])
                            }}
                        />
                    }
                </div>
            </div>
            <div className="container">
                {drilldownLevel > 0 && positionFilter.length < 3 &&
                    <div className="mt-1 mb-5">
                        <button className="btn btn-info" onClick={() => setPositionFilter(positions)}>Limpiar filtro de
                            posiciones
                        </button>
                    </div>
                }
                {drilldownLevel >= 1 && positionFilter.map((position) => {
                    let playerCards = playerValues.filter((player) => player.position === position.label).map((player) => {
                        let datum = [player]
                        console.log("datum", datum)
                        return (<div className="col-6" key={player.id}>
                            <div className="card my-3">
                                <div className="card-header">
                                    <h5 className="text text-muted subtitle">
                                        {player.id}
                                    </h5>
                                </div>
                                <div className="card-body">
                                    <div className="row w-100" style={{height: "300px"}}>
                                        <ResponsiveLine
                                            data={datum}
                                            margin={{top: 50, right: 20, bottom: 50, left: 100}}
                                            xScale={{type: 'point'}}
                                            yScale={{
                                                type: 'linear',
                                                min: 0,
                                                max: 'auto',
                                                stacked: false,
                                                reverse: false
                                            }}
                                            axisTop={null}
                                            axisRight={null}
                                            isInteractive={true}
                                            enableSlices="x"
                                            enableCrosshair={true}
                                            pointSymbol={(props) => {
                                                let imageURL = props.datum.symbol
                                                return (
                                                    <image
                                                        href={imageURL}
                                                        x="-15"
                                                        y="-15"
                                                        width={30}
                                                        height={30}
                                                    />
                                                )
                                            }}
                                            axisBottom={{
                                                orient: 'bottom',
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legendOffset: 36,
                                                padding: 0,
                                                renderTick: ({
                                                                 opacity,
                                                                 textAnchor,
                                                                 textBaseline,
                                                                 textX,
                                                                 textY,
                                                                 theme,
                                                                 value,
                                                                 x,
                                                                 y
                                                             }) => {
                                                    return (
                                                        <g
                                                            transform={`translate(${x},${y})`}
                                                            style={{opacity}}
                                                        >
                                                            <text
                                                                alignmentBaseline={textBaseline}
                                                                style={{fontSize: "12px"}}
                                                                textAnchor={textAnchor}
                                                                transform={`translate(${textX},${textY})`}
                                                            >
                                                                {getTspanGroups(value)}
                                                            </text>
                                                        </g>
                                                    )
                                                }
                                            }}
                                            axisLeft={{
                                                legend: `${col.name} (${col.unit})`,
                                                legendOffset: -70,
                                                legendPosition: 'middle',
                                                format: value => `${thousandsSeparator(value)} ${col.unit}`
                                            }}
                                            // legends={[
                                            //     {
                                            //         anchor: 'bottom-right',
                                            //         direction: 'column',
                                            //         justify: false,
                                            //         translateX: 100,
                                            //         translateY: 0,
                                            //         itemsSpacing: 2,
                                            //         itemDirection: 'left-to-right',
                                            //         itemWidth: 80,
                                            //         itemHeight: 20,
                                            //         itemOpacity: 0.75,
                                            //         symbolSize: 12,
                                            //         symbolShape: 'circle',
                                            //         symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                            //         effects: [
                                            //             {
                                            //                 on: 'hover',
                                            //                 style: {
                                            //                     itemBackground: 'rgba(0, 0, 0, .03)',
                                            //                     itemOpacity: 1
                                            //                 }
                                            //             }
                                            //         ]
                                            //     }
                                            // ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>)
                    })

                    return <div key={position.label} className="">
                        <div className="row">
                            <h1>{position.label}</h1>
                        </div>
                        <div className="row">
                            {playerCards}
                        </div>
                    </div>
                })
                }
            </div>
        </div>
    )
}

export default GPSChartComponent;