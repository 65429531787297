import {ResponsiveRadar} from "@nivo/radar";
import {Link} from "react-router-dom";
import {getTspanGroups, round, thousandsSeparator} from "../../Utils/utils";
import {Line, ResponsiveLine} from "@nivo/line";
import React, {useEffect} from "react";
import moment from "moment";
import generatePDF, {Margin} from "react-to-pdf";
import {ResponsiveBar} from "@nivo/bar";

const PlayerReport = ({player, resume, maximals, selectedHeaders}) => {

    const user = JSON.parse(localStorage.getItem("user"));
    const [chartData, setChartData] = React.useState([])
    const [chartKeys, setChartKeys] = React.useState([])

    const getAge = (date) => {
        let today = new Date();
        let birthDate = new Date(date);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    const targetRef = React.useRef();

    const chartHeaders = [
        {name: "Distancia Total", label: "Distancia Total", "units": "m"},
        {name: "Distancia Alta Intensidad(>21)", label: "Distancia Alta Intensidad(>21)", "units": "m"},
        {name: "High Metabolic Load Distance", label: "Distancia Alta Carga Metabolica", "units": ""},
    ]

    useEffect(() => {
        if (player && resume) {
            let data = {}
            let keys = new Set()

            if (resume[0].empty) {
                return
            }

            chartHeaders.forEach((header) => {
                let tmp = []
                let datum = {
                    group: header.label,
                }
                let match = resume[0].match

                if (resume[0].isLast) {
                    let matchName = user.company.id !== match.team.company._id ? match.team.company.name : match.opponent.company.name;
                    keys.add(matchName)
                    datum[matchName] = resume[0].values[header.label].value/1000
                }

                console.log(resume.length, resume[1], resume.length > 1 && !resume[1].empty)

                if (resume.length > 1 && !resume[1].empty) {
                    let before = resume[1]
                    let beforeName = user.company.id !== before.match.team.company._id ? before.match.team.company.name : before.match.opponent.company.name;
                    let beforeValue = before.values[header.label].value/1000
                    keys.add(beforeName)
                    datum[beforeName] = beforeValue
                }
                tmp.push(datum)
                data[header.label] = tmp
            })

            setChartKeys(Array.from(keys))




            // resume.slice(0, 2).reverse().forEach((datum) => {
            //     let match = datum.match
            //     let symbol = user.company.id !== match.team.company._id ? match.team.company.photo : match.opponent.company.photo;
            //     let values = datum.values
            //
            //
            //
            //
            //     let max = maximals[match._id]
            //
            //     let scores = []
            //     selectedHeaders.forEach((header) => {
            //         if (!values[header.label]){
            //             return
            //         }
            //         let value = values[header.label].value
            //         let score = 10 * (value / max[header.label])
            //         scores.push(score)
            //     })
            //
            //     let score = scores.reduce((a, b) => a + b, 0) / scores.length
            //
            //     if (!isNaN(score)) {
            //         data.push({
            //             x: `${moment(match.date).format("DD/MM")}`,
            //             y: round(score, 1),
            //             symbol,
            //             date: moment(match.date).format("DD/MM/YYYYTHH:mm:ss")
            //         })
            //     }
            // })
            setChartData(data)
        }
    }, [player, resume]);

    /*
    0: Object { group: "1", "Prep. contra: Universidad Católica": 4302 }
    1: Object { group: "2", "Entrenamientos previos": 4609 }
    2: Object { group: "-6", "Prep. contra: Universidad Católica": 6029 }
    3: Object { group: "-5", "Prep. contra: Universidad Católica": 4936, "Entrenamientos previos": 5749 }
    4: Object { group: "-4", "Prep. contra: Universidad Católica": 4062, "Entrenamientos previos": 5870 }
    5: Object { group: "-3", "Prep. contra: Universidad Católica": 5588, "Entrenamientos previos": 6765 }
    6: Object { group: "-2", "Prep. contra: Universidad Católica": 5703, "Entrenamientos previos": 3659 }
    7: Object { group: "-1", "Prep. contra: Universidad Católica": 5202, "Entrenamientos previos": 3141 }
     */


    return resume.length > 0 && resume[0].empty ? null :
        <div>
            <div className="row d-flex justify-content-end mr-5">
                <span className="ml-5" style={{fontSize: "12px", position: "relative", top: "40px"}}>
                    <Link to="#" onClick={async () => {
                        await generatePDF(targetRef, {
                            filename: `Reporte ${player.name}.pdf`, overrides: {
                                // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
                                pdf: {
                                    compress: true
                                },
                                // see https://html2canvas.hertzen.com/configuration for more options
                                canvas: {
                                    useCORS: true
                                }
                            },
                        });
                    }}>Descargar PDF</Link>
                </span>

            </div>
            <div ref={targetRef} style={{
                padding: "40px 20px",
            }}>
                <div className="row my-4">
                    {/*<div className="col-2">*/}
                        {/*<img className="card-img-top" src={player.photo} alt="Card image cap"/>*/}
                    {/*</div>*/}
                    <div className="col-9">
                        <h3>
                            {player.name}
                        </h3>
                        <p>
                            Edad: <span>{getAge(player.birthdate)} años</span>
                            <br/>
                            Posición: <span>{player.position}</span>
                        </p>
                    </div>
                </div>
                <div className="row">
                    {selectedHeaders.map((header) => {
                        let lastDatum = resume[0]
                        let title = header.label
                        let datum = lastDatum.values[header.label]
                        let value = header.label.toLowerCase() === "tiempo" ? `${round(datum.value / 60, 1)} min` : `${datum.value} ${header.units}`
                        let position = datum.ranking
                        let positionExtra = ""
                        if (position === 1) {
                            positionExtra = "(Mejor)"
                        } else if (position <= 5) {
                            positionExtra = "(Top 5)"
                        } else if (position <= 10) {
                            positionExtra = "(Top 10)"
                        } else if (position > 10) {
                            positionExtra = "(Fuera de top 10)"
                        }

                        let valueExtra = ""
                        if (resume.length > 1 && !resume[1].empty) {
                            let before = resume[1]
                            let beforeValue = before.values[header.label].value
                            let delta = datum.value - beforeValue
                            let deltaPercentage = 100 * delta / beforeValue
                            valueExtra = `(${round(deltaPercentage)}% desde el último partido)`
                        }
                        let max = maximals[lastDatum.match._id][header.label]
                        let score = 10 * (datum.value / max)

                        return (
                            <div className="col-4">
                                <div className="card p-2 my-1" key={header.label}>
                                    <div className="card-body">
                                        <h5 className="card-title">{title}</h5>
                                        <p className="card-text">Valor: {value} {valueExtra}</p>
                                        {header.label.toLowerCase() !== "tiempo" &&
                                            <p className="card-text">Score: {round(score, 1)}/10 {positionExtra}</p>}
                                    </div>
                                </div>
                            </div>

                        )
                    })}
                </div>
                <div className="row w-100" style={{minHeight: "400px"}}>
                    {Object.keys(chartData).map((key, index) => {

                        return <div className="col-4"><ResponsiveBar
                            data={chartData[key]}
                            keys={chartKeys}
                            indexBy="group"
                            groupMode="grouped"
                            margin={{top: 50, right: 0, bottom: 85, left: 80}}
                            padding={0.2}
                            valueFormat={value => `${round(value, 1)} km`}
                            labelFormat={value => `${round(value, 1)} km`}
                            valueScale={{type: 'linear'}}
                            axisLeft={{
                                format: value => `${round(value, 1)} km`,
                                legend: key,
                                legendOffset: -70,
                                legendPosition: 'middle',
                            }}
                            legends={index == 1 ? [
                                {
                                    dataFrom: 'keys',
                                    anchor: 'bottom',
                                    direction: 'column',
                                    justify: false,
                                    translateX: 0,
                                    translateY: 90,
                                    itemsSpacing: 0,
                                    itemWidth: 100,
                                    itemHeight: 30,
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 0.85,
                                    symbolSize: 20,
                                }
                            ] : []
                        }

                        /></div>
                    })}
                    {/*<Line height={300} width={1000}*/}
                    {/*    data={chartData}*/}
                    {/*    margin={{top: 50, right: 20, bottom: 50, left: 100}}*/}
                    {/*    xScale={{type: 'point'}}*/}
                    {/*    yScale={{*/}
                    {/*        type: 'linear',*/}
                    {/*        min: 0,*/}
                    {/*        max: 10,*/}
                    {/*        stacked: false,*/}
                    {/*        reverse: false*/}
                    {/*    }}*/}
                    {/*    axisTop={null}*/}
                    {/*    axisRight={null}*/}
                    {/*    isInteractive={true}*/}
                    {/*    enableSlices="x"*/}
                    {/*    enableCrosshair={true}*/}
                    {/*    pointSymbol={(props) => {*/}
                    {/*        let imageURL = props.datum.symbol*/}
                    {/*        return (*/}
                    {/*            <image*/}
                    {/*                href={imageURL}*/}
                    {/*                x="-15"*/}
                    {/*                y="-15"*/}
                    {/*                width={30}*/}
                    {/*                height={30}*/}
                    {/*            />*/}
                    {/*        )*/}
                    {/*    }}*/}
                    {/*    axisBottom={{*/}
                    {/*        orient: 'bottom',*/}
                    {/*        tickSize: 5,*/}
                    {/*        tickPadding: 5,*/}
                    {/*        tickRotation: 0,*/}
                    {/*        legendOffset: 36,*/}
                    {/*        padding: 0,*/}
                    {/*        renderTick: ({*/}
                    {/*                         opacity,*/}
                    {/*                         textAnchor,*/}
                    {/*                         textBaseline,*/}
                    {/*                         textX,*/}
                    {/*                         textY,*/}
                    {/*                         theme,*/}
                    {/*                         value,*/}
                    {/*                         x,*/}
                    {/*                         y*/}
                    {/*                     }) => {*/}
                    {/*            return (*/}
                    {/*                <g*/}
                    {/*                    transform={`translate(${x},${y})`}*/}
                    {/*                    style={{opacity}}*/}
                    {/*                >*/}
                    {/*                    <text*/}
                    {/*                        alignmentBaseline={textBaseline}*/}
                    {/*                        style={{fontSize: "12px"}}*/}
                    {/*                        textAnchor={textAnchor}*/}
                    {/*                        transform={`translate(${textX},${textY})`}*/}
                    {/*                    >*/}
                    {/*                        {getTspanGroups(value)}*/}
                    {/*                    </text>*/}
                    {/*                </g>*/}
                    {/*            )*/}
                    {/*        }*/}
                    {/*    }}*/}

                    {/*    axisLeft={{*/}
                    {/*        legend: `Score`,*/}
                    {/*        legendOffset: -70,*/}
                    {/*        legendPosition: 'middle',*/}
                    {/*        format: value => `${round(value, 1)}`,*/}

                    {/*    }}*/}
                    {/*/>*/}
                </div>
            </div>
        </div>
}

export default PlayerReport;