import {useEffect, useState} from "react";
import HTTPServices from "../../Utils/HTTPServices";
import {TailSpin} from "react-loader-spinner";
import {Link} from "react-router-dom";
import moment from "moment";

const MatchResumeCard = ({lastMatch}) => {

    const [match, setMatch] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let client = new HTTPServices();
        let promise = lastMatch ? client.getLastMatch() : client.getNextMatch();
        promise.then((response) => {
            setMatch(response.data.match);
            setLoading(false);
        }).catch((error) => {
            console.error(error);
            setLoading(false);
        });
    }, [lastMatch]);


    return (
        <div className="card">
            {loading ? <div className="card-header">
                <TailSpin
                    visible={true}
                    height="80"
                    width="80"
                    color="#011634"
                    ariaLabel="tail-spin-loading"
                    radius="2"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div> : null}
            { match ? <>
                <div className="card-header">
                    <h3>{lastMatch ? "Último partido" : "Próximo partido"}</h3>
                    <span className="text-small">{moment(match.date).format("DD-MM-YYYY")}</span>
                </div>
                <div className="card-body">
                    <div className="row justify-content-between">
                        <div className="col-4 d-flex flex-column">
                            <img src={match.team.company.photo} alt="team-logo" className="w-100"/>
                            <span className="text-center">{match.team.company.name}</span>
                        </div>
                        <div className="col-2 d-flex flex-column justify-content-center">
                            <span className="text-center">V/S</span>
                        </div>
                        <div className="col-4 d-flex flex-column">
                            <img src={match.opponent.company.photo} alt="team-logo" className="w-100"/>
                            <span className="text-center">{match.opponent.company.name}</span>
                        </div>
                    </div>

                    <h5 className="card-title text-center h3">{match.result && match.result}</h5>
                    {lastMatch &&
                        <div className="row d-flex justify-content-end mx-3">
                            <Link to={`/match/${match._id}/`} className="btn btn-primary">Ver resumen</Link>
                        </div>
                    }
                </div>
            </> : <div className="card-body">
                <h5 className="card-title text-center h3">No hay partidos</h5>
            </div>
            }
        </div>
    )
}

export default MatchResumeCard;