class PriorityQueue {
  constructor(comparator = (a, b) => b - a, getter = (a) => a.value) {
      this._comparator = comparator;
      this._getter = getter;
      this.queue = [];
  }

  enqueue(value) {
    this.queue.push(value);
    this.queue.sort((a, b) => this._comparator(this._getter(a), this._getter(b)));
  }

  dequeue() {
    return this.queue.shift();
  }

  isEmpty() {
    return this.queue.length === 0;
  }
}

export default PriorityQueue;